<!--
 * @Autor: oops.liu
 品牌专区
-->
<template>
  <div class="brand-box">
    <i class="mask" v-if="showTab" @click="maskHandle"></i>
    <div class="top_content">
      <van-nav-bar
        title="品牌专区"
        left-arrow
        :border="false"
        @click-left="handleLeft"
      />
      <div class="page_tabs">
        <div class="tabs-left">
          <van-tabs
            v-model="active"
            color="#fff"
            background="transparent"
            title-inactive-color="#fff"
            title-active-color="#fff"
            line-height="2"
            line-width="50"
            @change="tabChange"
          >
            <van-tab
              v-for="(item, index) in tabList"
              :key="index"
              :title="item.name"
              :name="item.id"
            />
          </van-tabs>
        </div>
        <div class="tabs-right" @click="openTabHandle">
          <van-icon name="wap-nav" />分类
        </div>
      </div>
      <div class="dropdown-tab" :class="{ close: !showTab }">
        <div
          class="tab-item"
          :class="{ 'tab-active': item.id === active }"
          v-for="(item, index) in tabList"
          :key="index + 'b'"
          @click="tabSelectHandle(item)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="content-box">
      <scroller
        ref="my_scroll"
        :noDataText="noDataText"
        :on-infinite="infinite"
        :on-refresh="refresh"
      >
        <div class="content">
          <div class="seller-list" v-if="listData.length > 0">
            <div
              class="seller-item"
              v-for="(item, index) in listData"
              :key="index + 'c'"
            >
              <div class="seller-top" @click="sellerHandle(item)">
                <div class="bg">
                  <img v-if="item.actImg" :src="item.actImg" alt="" />
                </div>
                <div class="info">
                  <div class="logo">
                    <img :src="item.logo" alt="" />
                  </div>
                  <p class="hint">{{ item.actName }}</p>
                  <p class="seller-name">{{ item.name }}品牌专场</p>
                  <div class="active-time">
                    <img
                      src="~@/assets/img/time.png"
                      alt=""
                    />仅剩<van-count-down
                      :time="item.actEndTime1 - new Date().getTime()"
                    >
                      <template #default="timeData">
                        <div class="time-info" v-if="timeData.days > 1">
                          <span class="days">{{ timeData.days }}</span
                          >天
                        </div>
                        <div class="time-info" v-else>
                          <span class="block">{{ timeData.hours }}</span>
                          <span class="colon">:</span>
                          <span class="block">{{ timeData.minutes }}</span>
                          <span class="colon">:</span>
                          <span class="block">{{ timeData.seconds }}</span>
                        </div>
                      </template>
                    </van-count-down>
                  </div>
                </div>
              </div>
              <div class="seller-bottom">
                <div class="recommend">
                  <div
                    class="recommend-item"
                    v-for="(r, rx) in item.actGoods"
                    :key="rx + 'd'"
                    @click.stop="handleGoods(r)"
                  >
                    <img :src="r.picUrls[0]" alt="" />
                    <div class="price-box">
                      <span class="price"> ￥{{ r.priceDown }} </span>
                      <del class="old-price">￥{{ r.marketPrice }}</del>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </scroller>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import scrollMethods from "@/mixin/scroll.js";
import handleGoods from "@/mixin/handleGoods.js";
import handleLeft from "@/mixin/handleLeft.js";
import topHead from "components/shop/topHead.vue";
export default {
  components: { topHead },
  mixins: [scrollMethods, handleGoods, handleLeft],
  props: {},
  data() {
    return {
      active: 0,
      showTab: false,
      seller: {},
      tabList: [],
    };
  },
  computed: {},
  methods: {
    ...mapActions({
      load: "active/getgoodsList",
      cateLoad: "active/getCategory",
    }),
    async loadInfo(isRefresh = false) {
      const params = {
        current: this.pages,
        scopeId: this.active,
      };
      const data = await this.load({ ...params });
      this.dataLength = data.records ? data.records.length : 0;
      if (isRefresh) {
        this.listData = data.records;
      } else {
        this.listData = this.listData.concat(data.records);
      }
      return data;
    },
    tabSelectHandle(tab) {
      this.active = tab.id;
      this.showTab = false;
      this.pages = 1;
      this.listData = [];
      this.loadInfo();
    },
    tabChange(id, name) {
      this.active = id;
      this.pages = 1;
      this.listData = [];
      this.loadInfo();
    },
    openTabHandle() {
      this.showTab = true;
    },
    maskHandle() {
      this.showTab = false;
    },
    sellerHandle(info) {
      if (info.actEndTime1 > new Date().getTime()) {
        this.$router.push("/exclusive/" + info.id);
      } else {
        this.$toast.fail("活动已过期");
      }
    },
    jump(info) {
      console.log(info);
    },
  },
  created() {
    // 获取分页信息
    this.cateLoad().then((list) => {
      this.tabList = list;
      this.active = list[0].id || undefined;
      this.loadInfo();
    });
  },
};
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
